const state = {
  // 侧边栏展合状态
  isCollapse:false,
  // 分辨率判定
  device: 'desktop',// desktop mobile 993
  device2: 'desktop',// desktop mobile 1550
  isSmall: 'normal',// small normal 750
  // 移动端判定
  isMobile:'0',// 0 1

  lang: localStorage.getItem('lang') || 'en',

  // 主题
  theme: localStorage.getItem('theme')||'dark',// light dark

  // 版本号
  version:'6.3.1',
  // 注册来源
  isTwitter:0,//0-默认 1-twitter 2-qrcode
}

const mutations = {
  // 侧边栏展合状态切换
  SET_IS_COLLAPSE:(state, isCollapse) => {
    state.isCollapse = isCollapse
  },
  // 分辨率判定
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  TOGGLE_DEVICE2: (state, device2) => {
    state.device2 = device2
  },
  VW_DEVICE: (state, isSmall) => {
    state.isSmall = isSmall
  },
  // 移动端判定
  SWITCH_ISMOBILE:(state, isMobile)=>{
    state.isMobile = isMobile
  },

  SET_LANG: (state, lang) => {
    state.lang = lang
  },
  SET_THEME:(state,theme)=>{
    state.theme = theme
  },
  // 注册来源
  SET_IS_TWITTER:(state,isTwitter)=>{
    state.isTwitter = isTwitter
  },
}

const actions = {
  // 分辨率判定
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  toggleDevice2({ commit }, device2) {
    commit('TOGGLE_DEVICE2', device2)
  },
  vwDevice({ commit }, isSmall) {
    commit('VW_DEVICE', isSmall)
  },
  // 移动端判定
  switchIsMobile({ commit }, isMobile){
    commit('SWITCH_ISMOBILE', isMobile)
  },

  setLang({ commit }, lang) {
    // this.$i18n.locale = value;
    localStorage.setItem('lang', lang);
    commit('SET_LANG', lang);
    // 交易所
    this.dispatch('markets/getList')
    // 收藏列表
    this.dispatch('markets/getCollectList')
    // 会员套餐列表
    this.dispatch('markets/getVipList')
    // this.dispatch('markets/getPayInfo')
  },
  setLangOnly({ commit }, lang) {
    return new Promise((resolve, reject) => {
      // this.$i18n.locale = value;
      localStorage.setItem('lang', lang);
      commit('SET_LANG', lang);
      resolve()
    })
  },

  // 主题
  steTheme({ commit }, theme){
    localStorage.setItem('theme', theme);
    commit('SET_THEME', theme);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
